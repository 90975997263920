import React, { Component, Fragment } from 'react';
import { CataloguesService } from '../../services/LandingV4/catalogues';
import { ButtonDegrade, CardElevation, TitleIcon } from '@findep/mf-landings-core';
import { Grid, InputLabel, FormControl, Snackbar, MenuItem, Select, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { OfferService } from '../../services/LandingV4/offer';
import { navigate } from '../../helpers/queryNavigate';


class CreditDestination extends Component {
  state = {
    creditApplicationId: '',
    creditDestinations: [],
    selectedDestinationId: '',
    isLoading: true,
    errorMessage: null,
    errorService: false,
  }

  async componentDidMount() {
    let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    const creditApplicationId = flujo?.creditApplicationId

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'destinoCredito',
      'eventCategory': 'RenovacionesDigital',
      'eventAction': 'vistaTuboForm',
      'eventLabel': 'destinoCredito-View', 
      'creditAppID': creditApplicationId
   })  

    try {
      const catalogues = new CataloguesService();
      const { data: { creditDestinationIds } } = await catalogues.getCreditDestinations();
      const defaultDestinationId = creditDestinationIds[0]?.id;

      this.setState({ creditApplicationId, creditDestinations: creditDestinationIds, selectedDestinationId: defaultDestinationId, isLoading: false });
    } catch (error) {
      console.log(error);
    }
  }

  onButtonClick = async () => {
    try {
      const offer = new OfferService(this.state.creditApplicationId);
      const response = await offer.sendCreditDestination(this.state.creditApplicationId, this.state.selectedDestinationId);

      if (response.status === 200) {

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'destinoCreditoSubmit',
          'eventCategory': 'RenovacionesDigital',
          'eventAction': 'vistaTuboForm',
          'eventLabel': 'destinoCredito-Submit',
          'creditAppID': this.state.creditApplicationId
       })

        navigate(this.props.pageContext.next.path)
      }
    } catch (error) {
      console.log(error);
      this.setState({ errorService: true })
    }
  }

  onSelectChange = async (event) => {
    const { value } = event.target;
    this.setState({ selectedDestinationId: value });
  }

  render() {
    const { creditDestinations, selectedDestinationId, isLoading } = this.state;
    return (
      <div style={{ height: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={CreditCardIcon} title='Destino Crédito' textColor />
          </Grid>
        </Grid>
        <br />

        <CardElevation>
          <Typography variant="h6" component="h6">
            Por favor selecciona el destino del crédito
          </Typography>

          <br />

          {isLoading ? (
            <Typography>Cargando opciones...</Typography>
          ) : (
            <FormControl >
              <InputLabel id="demo-simple-select-label">Destino</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.selectedDestinationId}
                label="Destino"
                onChange={this.onSelectChange}
              >
                {creditDestinations.map((dest) => (
                  <MenuItem key={dest.id} value={dest.id}>
                    {dest.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Grid container>
            <Grid item style={{ textAlign: "center" }} xs={12}>
              <br />
              <ButtonDegrade
                id="firma-digital-continuar-button"
                icon={MobileFriendly}
                textButton="CONTINUAR"
                disabled={!selectedDestinationId}
                onClick={this.onButtonClick}
              />
            </Grid>
          </Grid>
        </CardElevation>

        <Snackbar
          autoHideDuration={6000}
          onClose={() => this.setState({ errorService: false, errorMessage: null })}
          open={this.state.errorService}
        >
          <Alert
            onClose={() => this.setState({ errorService: false, errorMessage: null })}
            severity="warning"
            variant="filled"
          >
            {this.state.errorMessage || 'Lo sentimos, por el momento el servicio no se encuentra disponible'}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default CreditDestination;